<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Search Tags</h4>
            </b-col>
            <b-col class="text-right">
              <b-button variant="primary" size="sm" squared @click="addTag()">Add Tags</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="4">
              <label>Tag</label>
              <b-form-input v-model="searchTerm" @keyup="search"></b-form-input>
            </b-col>
           
          
          </b-row>
          
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="12">           
              <b-table striped hover
                       sort-icon-left
                       :items="tableData.dataSource" 
                       :fields="tableData.tableColumns"
                       :busy="tableData.isLoading" 
                       @row-clicked="openTag"
                       :per-page="tableData.resultsPerPage"
                       id="SearchTag"
                       :current-page="tableData.currentPage">

                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner style="width: 3rem; height: 3rem;"></b-spinner>
                  </div>
                </template>        
                <template #cell(actions)="row">
                  <b-row align-v="center" align-h="end">
                    <b-button @click="openTag(row.item)" size="sm" class="btn-icon">
                      <b-icon-chevron-right></b-icon-chevron-right>
                    </b-button>
                  </b-row>
                </template>
              </b-table>
              <b-row align-h="center" >
                <b-pagination
                    v-model="tableData.currentPage"
                    :total-rows="rows"
                    :per-page="tableData.resultsPerPage"
                    aria-controls="SearchTag"
                ></b-pagination>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>
<style >
.primary{
background-color: #ffcb14;
color: #222222;
}
.primary:hover{
    color: #fff;
}
.page-item.active .page-link {
    z-index: 3;
    color: #222222;
    background-color: #ffcb14;
    border-color: #ffcb14;
}
</style>
<script>

import {mapMutations} from "vuex";
import api from "../../../api";

export default {
  name: "SearchTag",
  data: () => ({
    tableData: {
      resultsPerPage: 10,
      currentPage: 1,
      dataSource: [],
      isLoading: true,
      tableColumns: [
        {
          label: 'Name',
          key: 'name',
          sortable: true,
          tdClass: '',
        },
        {
          label: 'Link',
          key: 'displayLinks',
          sortable: true,
          tdClass: '',
        },
      
        {
          label: '',
          key: 'actions',
          sortable: false,
          tdClass: ''
        }
      ]
    },
   
    tags: [],
    searchTerm: '',
    
  }),
  created() { 
    this.setBreadcrumb([
      {
        text: 'Tags'
      },
    ]);

   const s = (data) => {
      this.tableData.dataSource = data;
      this.tags = data;
      console.log( data);

       this.tableData.isLoading = false;
   };

   const e = (msg) => {
     console.log(msg);
   };

    api.getAllTags(s, e);
  },

  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
   

    search() {
      this.tableData.dataSource = this.tags.filter((x) => 
        x.name.toLowerCase().includes(this.searchTerm.toLowerCase()) || x.displayLinks.toLowerCase().includes(this.searchTerm.toLowerCase())
      );  
    },

   
   openTag(tags) {
    
      localStorage.setItem("view_tag_id", tags.id);
      this.$router.push({path: '/admin/ManageTags/ViewTag'})
    },
    
    addTag() {
      this.$router.push({path: '/admin/ManageTags/CreateTag'})
    }
  },
  computed: {
      rows() {
          return this.tableData.dataSource.length
      },
  },
}
</script>

<style scoped>

</style>